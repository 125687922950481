.name {
    font-size: 80px;
    font-weight: bold;
}

.sub {
    font-size: 50px;
    font-weight: bold;

}

.resume-text{
    color: white;
    font-size: 30px;
    padding-top: 3px;
    padding-bottom: 1px;
  }

  .button-resume{
    margin-top: 20px;
    padding-top: 3px;
    padding-bottom: 1px;
  }
  