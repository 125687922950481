.accordian-main {
    cursor: pointer;
    display: inline-block;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-size: 14px;
    background-color: rgb(249, 248, 250);
    border: none;
    border-radius: 4px;
  
    padding: 10px;
    color: #fff;
  }

  .projects-body {
    /*background-image: url(../assets/projectscover.jpg);
    background-size: cover;*/
    padding-top: 50px;
  
    
  }

  .pt-3{
    color: #36FCEF;
    padding-top: 300px;
  }
  
  
  .list-styles {
    list-style-type: circle;
  }
  
  .image-style1 {
    width: 3em;
  }

  .card-img-top {
    height: 280px;
    width: 316px;
    
  }

  .btn.btn-primary {
    margin: 5px
  }

  .project-image{
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center
  }

  .projects {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: -100px;
    margin-left: -100px;
    
  }

  .project{
    padding-right: 20px;
    padding-left: 5px;
    
    
    
  }

  .container-projects{
    padding: 100px;
    padding-top: 10px;
  }

  .card{
    border: 2px solid black;
  }

  .accordion{
   
    padding: 2px 2px 2px 2px;
    
  }

  .details{
    color: white;
    padding: 0;
    margin: 0;
  }

  

  .card-header{
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #003973;
    
      }

      h5{
        font-size: 10px;
        text-align: center;
        margin-left: -11px;
        
        

      }

      .details.icon{
       text-align: center;
      }

      .mock-html5{
        font-size: 10px;
        text-align: center;
        margin-left: -10px;
      }

      .mock-css3{
        font-size: 10px;
        text-align: center;
        margin-left: -8px;
      }

      .mock-javascript{
        font-size: 10px;
        text-align: center;
        margin-left: -18px;
        margin-right: -15px;
      }

      .mock-git{
        font-size: 10px;
        text-align: center;
        margin-left: -10px;
      }

      .mock-api{
        font-size: 10px;
        text-align: center;
        margin-left: -5px;
      }

      .mock-git{
        font-size: 10px;
        text-align: center;
        margin-left: -10px;
      }

      .swanson-html5{
        font-size: 10px;
        text-align: center;
        margin-left: 5px;
      }

      .swanson-css3{
        font-size: 10px;
        text-align: center;
        margin-left: -10px;
      }

      .swanson-javascript{
        font-size: 10px;
        text-align: center;
        margin-left: -18px;
        margin-right: -15px;
      }

      .swanson-api{
        font-size: 10px;
        text-align: center;
        margin-left: -5px;
      }

      .swanson-git{
        font-size: 10px;
        text-align: center;
        margin-left: -10px;
      }

      .game-html5{
        font-size: 10px;
        text-align: center;
        margin-left: 5px;
      }

      .game-css3{
        font-size: 10px;
        text-align: center;
        margin-left: -13px;
      }

      .game-javascript{
        font-size: 10px;
        text-align: center;
        margin-left: -25px;
        margin-right: -15px;
      }

      .game-git{
        font-size: 10px;
        text-align: center;
        margin-left: -10px;
      }

      .button {
        background-color: #003973;
      }


      

      


  
  