.App {
  text-align: center;
  
}

.color-gradient{
  /*background-image: linear-gradient(#2b32b2,#1488cc);*/
  background-image: linear-gradient(#010b0f,#203a43, #bbd2c5);
  /*background-image: linear-gradient(#00416a,#e4e5e6);*/
  background-size: cover;

}