.custom-img {
    height: 100vh;
    object-fit: cover;
}

.home{
    margin-bottom: -44px;
}

.carousel-indicators{
    visibility: hidden;
}

.turn{
    visibility: hidden;
}