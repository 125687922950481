.skills-container {
    /*background-image: url(../assets/whitebackground.jpg);*/
    padding-top: 100px;
    padding-bottom: 50px;
    /*background-size: cover;*/
    
    
    
}

.container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}


.image {
    height: 100px;
    width: 100px;
    border: 1px black solid
}

.skills {
    display:flex;
    padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  
  
  
  
  
}

p {
    text-align: center;
}

h3{
    margin-bottom: -20px;
    color: #36FCEF;
}

.individual-skills {
    color: white;
}











