
  .animate-navbar {
    //box-shadow: 1px 1px 1px #222;
    //animation: moveDown 0.5s ease-in-out;
    background: rgba(51, 170, 51, .1);
    z-index: 1;
  }
  
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }

  .mr-auto{
    font-size: 20px;
    font-weight: bold;
    color: white;
    

    
   
}

.navbar .navbar-brand{
  font-size: 45px;  
  font-weight: bold;
  color: white;
  
    
    
    
}







