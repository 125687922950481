.contact-jumbotron {
  background-image: linear-gradient(#536976, #bbd2c5);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px 50px 25px;
  
}

#contact{
  padding-bottom: 50px;
}
  